<!-- Step 3 -->
<template id="step-4">
  <div v-if="props.stepFourBudgetForm" class="main-column">
    <div>
      <h2 class="title-main-column">Revisa tu Cotización</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
      <p>Antes de confirmar tu cotización, por favor revisa el detalle y los totales.</p>
      <!-- <p>Una vez confirmada unos de nuestros representantes se pondrá en contacto para gestionar tu cotización.</p> -->
    </div>

    <f7-block class="totals-block no-margin">
      <f7-row>
        <div class="col-20"></div>
        <div class="col-100 medium-60">
          <div style="border: 1px dashed rgb(204, 204, 204); padding: 4px 0px 9px 0px; margin: 30px auto 30px auto; border-radius: 5px;">
            <f7-row style="display: flex; justify-content: center; align-items: center;">
              <div class="col-80">
                <f7-list no-hairlines class="no-margin">
                  <f7-list-input
                    floating-label
                    outline
                    name="cupon"
                    label="¿Tienes un Cupón?"
                    type="text"
                    :disabled="props.coupon_applied"
                    @input="(e) => {props.coupon = e.currentTarget.value}"
                  >
                  </f7-list-input>
                </f7-list>
              </div>
              <div class="col-20" style="margin-top: 7px; border: 2px solid #253858; border-radius: 7px; margin-right: 16px;">
                <f7-button medium fill @click="checkCoupon" style="margin: 0 auto;">
                  <i class="fas fa-check"></i>
                </f7-button>
              </div>
            </f7-row>
          </div>
        </div>
        <div class="col-20"></div>
      </f7-row>
    </f7-block>

    <f7-block class="totals-block no-margin">
      <f7-row class="totals">
        <div class="col-100 xsmall-100 small-100 medium-50">Costo de Transporte</div>
        <div class="col-100 xsmall-100 small-100 medium-50 total-importe">$ {{ props.totalShipping.toFixed(2) }}</div>
      </f7-row>

      <f7-row class="totals">
        <div class="col-100 xsmall-100 small-100 medium-50">Costo de Retiro</div>
        <div class="col-100 xsmall-100 small-100 medium-50 total-importe">$ {{ props.totalPickUp.toFixed(2) }}</div>
      </f7-row>

      <f7-row v-if="props.totalDescuento > 0" class="totals-discount">
        <div class="col-100 xsmall-100 small-100 medium-50">Descuento</div>
        <div class="col-100 xsmall-100 small-100 medium-50 total-descuento">($ {{ props.totalDescuento.toFixed(2) }})</div>
      </f7-row>

      <f7-row class="totals">
        <div class="col-100 xsmall-100 small-100 medium-50">
          <span>Costo Neto (No contiene Seguro / Iva)</span>
          <!-- <br>
          <span style="font-size: 12px; font-weight: 400; color: #6e6e6e;">El precio incluye la entrega a domicilio</span> -->
        </div>
        <div class="col-100 xsmall-100 small-100 medium-50 total-importe">$ {{ props.total.toFixed(2) }}</div>
      </f7-row>
    </f7-block>

    <f7-block class="no-margin">
      <f7-list accordion-list>
        <f7-list-item class="terms-conditions-title" accordion-item title="Condiciones Generales">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">El importe cotizado no contempla seguro ni I.V.A.</f7-list-item>
              <f7-list-item class="terms-conditions-item">I.V.A 21 % , no aplica a la provincia de Tierra del Fuego.</f7-list-item>
              <f7-list-item class="terms-conditions-item">Seguro: 1% del Valor Declarado de la mercadería (no aplica a las provincias de Tierra del Fuego y Santa Cruz. En ese caso es del 1,5% del Valor Declarado de la mercadería.</f7-list-item>
              <f7-list-item class="terms-conditions-item">Aclaración Importante: Si el monto de la carga supera el millón de pesos, el porcentaje a cobrar del seguro será del 2% del Valor Declarado.</f7-list-item>
              <f7-list-item class="terms-conditions-item">Para los envíos a Tierra del Fuego, contemplar impuesto del cruce de aduana</f7-list-item>
              <f7-list-item class="terms-conditions-item">Aclaración: NO hacemos viajes internacionales, ni retiramos en depósitos fiscales y aeropuerto de Ezeiza. Ante cualquier inquietud, no dude en consultar vía mail o llamando al 5091-9000 interno 121/3.</f7-list-item>
              <f7-list-item class="terms-conditions-item">LA PRESENTE COTIZACIÓN TIENE UNA VIGENCIA DE 15 DÍAS Y LOS PRECIOS ANTES MENCIONADOS ESTÁN SUJETOS A MODIFICACIÓN SIN PREVIO AVISO (S.E.U.O).</f7-list-item>
              <f7-list-item class="terms-conditions-item">Estamos ubicados en Pergamino 3751, NAVE A (Mod. 20 al 27), Villa Soldati (C.A.B.A.), C.P. 1437; de lunes a viernes de 8 a 18 hs.</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Retiro de Carga">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">El retiro se realiza únicamente en puerta, no accedemos a ningún domicilio.</f7-list-item>
              <f7-list-item class="terms-conditions-item">El horario para los mismos es de lunes a viernes de 9 a 18, no se coordina una franja horaria específica.</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Seguro">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">El Seguro cubre: Choque, vuelco, incendio, robo.</f7-list-item>
              <f7-list-item class="terms-conditions-item">El Seguro no cubre: Bultos con embalaje deficiente. Muebles laqueados no tienen cobertura de seguro.</f7-list-item>
              <f7-list-item class="terms-conditions-item">El mismo, no cubre daños de objetos que contengan vidrio y no hayan sido embalados de forma adecuada. Seguro por un mínimo importe.</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Embalaje">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">Mercadería a embalar por el Remitente. NO PROVEEMOS NI MATERIAL NI SERVICIO DE EMBALAJE.</f7-list-item>
              <f7-list-item class="terms-conditions-item">El embalaje debe ser realizado con cartón corrugado y film / cinta adhesiva para cualquier tipo de envío.</f7-list-item>
              <f7-list-item class="terms-conditions-item">En el caso de mármoles, vidrios, vajillas, obras de arte, placas / esculturas de yeso y artículos diseñados en barro, deben ir embalados en cajón / esqueleto de madera (EXCLUYENTE).</f7-list-item>
              <f7-list-item class="terms-conditions-item">Los bultos (en caso de ser varios) deben estar numerados y etiquetados con los datos del destinatario.</f7-list-item>
              <f7-list-item class="terms-conditions-item">LA EMPRESA NO SE RESPONSABILIZARÁ POR ROTURAS EN EL VIAJE SI LA MERCADERÍA NO ESTÁ DEBIDAMENTE EMBALADA.</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Documentación">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">Remito por triplicado (datos completos de remitente y destinatario con sus T.E y CUIT)</f7-list-item>
              <f7-list-item class="terms-conditions-item">En caso de no contar con remito, adjuntar fotocopia del D.N.I. de la persona que despacha la carga en el depósito. Los bultos deben figurar en el remito de envío con su detalle y valor declarado.</f7-list-item>
              <f7-list-item class="terms-conditions-item">Factura de venta, original y copia (Si el envío es a Tierra del Fuego, es excluyente presentarla, puede ser A, B, C, o E), si el envío no es una venta, se debe adjuntar una carta de declaración jurada a la aduana y fotocopia de D.N.I.</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Pago">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">El pago puede realizarse en origen o en destino, según decida Ud., pero no puede dividirse, se paga en un solo lugar.</f7-list-item>
              <f7-list-item class="terms-conditions-item">En ambos casos, puede realizarse con efectivo, cheque a 5 días como máximo, o a través de transferencia bancaria.</f7-list-item>
              <!-- <f7-list-item class="terms-conditions-item">No aceptamos tarjetas de crédito ni débito.</f7-list-item> -->
              <f7-list-item class="terms-conditions-item">Podrá abonarse también con Mercado Pago (Consultar).</f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

        <f7-list-item class="terms-conditions-title" accordion-item title="Envíos a Tierra del Fuego">
          <f7-accordion-content>
            <f7-list>
              <f7-list-item class="terms-conditions-item">
                <f7-row>
                  <f7-col>
                    <p>AT. SEÑORES PROVEEDORES</p>
                    <p>EN LOS RTOS. DEBE FIGURAR</p>
                    <br>
                    <ul>
                      <li><strong>0.</strong> DE SER PAGO EN ORIGEN DEBE INDICAR EN EL REMITO “PAGO EN ORIGEN” CONJUNTAMENTE CON CORREO ELECTRONICO Y UN NUMERO CON Whatsapp.</li>
                      <li><strong>1.</strong> NOMBRE O RAZON SOCIAL, DIRECCIÓN Y TEL. DEL REMITENTE.</li>
                      <li><strong>2.</strong> NOMBRE O RAZON SOCIAL, DIRECCIÓN, TEL. Y CUIT DEL DESTINATARIO.</li>
                      <li><strong>3.</strong> DESCRIPCIÓN DE LA CARGA (DETALLADO).</li>
                      <li><strong>4.</strong> VALOR DECLARADO</li>
                      <li><strong>5.</strong> TODOS LOS RTOS DEBEN VENIR POR TRIPLICADO O CON FOTOCOPIAS</li>
                      <li><strong>6.</strong> PARA LOS ENVIOS A USHUAIA Y RIO GRANDE COPIAS DE FACTURAS (VENTAS O ACTIVIDAD COMERCIAL) MUY IMPORTANTE PARA MERCADERIAS IMPORTADAS DEBEN FIGURAR LOS NROS. DE DESPACHO CON LOS QUE INGRESARON AL PAIS LEY (22415). CASO CONTRARIO QUE NO ESTEN COMPLETOS LOS RTOS CON TODA LA INFORMACIÓN, LAS CARGAS SERAN DECOMISADAS POR ADUANA.</li>
                      <li><strong>7.</strong> PRODUCTOS DE FARMACIA (MEDICAMENTOS Y COSMETICOS) DENBEN TENER LA AUTORIZACION DE ANMAT, Administración Nacional de Medicamentos, Alimentos y Tecnología Médica (LEY 16463, Normas sobre Contralor Técnico y Económica de los Medicamentos, Drogas y todo otro producto de uso y aplicación en la medicina humana.)</li>
                      <li><strong>8.</strong> E.O.N. No posee permiso para despachar Tiner, Diluyentes, Armas de Fuego y municiones.</li>
                      <li><strong>9.</strong> Todos los vinos, bebidas alcohólicas, Neumáticos y Pinturas deben llevar pegadas la siguiente etiqueta: PARA SER CONSUMIDO EXCLUSIVAMENTE EN LA PROV. DE TIERRA DEL FUEGO, ANTARTIDA E ISLAS DEL ATLANTICO SUR</li>
                      <li><strong>10.</strong> Material PETROLERO, es decir, dirigido a petroleras, debe ingresar con copia de FACTURA A porque las petroleras no entran en el régimen de exentos.</li>
                      <li>
                        <strong>11.</strong> Si alguno de los bultos, regresa al continente, debe mencionarlo en el Remito origen:<br>
                        Ejemplo:  Bulto Nº 2 contiene…. Regresa a continente<br>
                        Bulto Nº 5 contiene……Regresa a Continente
                      </li>
                    </ul>
                  </f7-col>
                </f7-row>
              </f7-list-item>
              <f7-list-item class="terms-conditions-item">
                LOS NROS. DE DESPACHO DE IMPORTACION TIENEN QUE ESTAR COMPLETOS (EJEMPLO: 13  001 IC04 Y/0 IC05 - NUMERO Y LETRA)
                - 13 ES EL AÑO
                - 001 ES LA ADUANA, EN ESTE CASO ES BUENOS AIRES
                IC04 – IC05
                NUMERO
                LETRA DIGITO CONTROL.-
              </f7-list-item>
            </f7-list>
          </f7-accordion-content>
        </f7-list-item>

      </f7-list>

      <f7-list no-hairlines class="no-margin">
        <!-- Origen Retiro -->
        <f7-list-item
          style="margin-bottom: 30px; white-space: normal !important; text-align: left;"
          checkbox title="Acepto todos los términos y condiciones"
          name="terminos-condiciones"
          @change="props.termsAccepted = !props.termsAccepted"
        ></f7-list-item>
      </f7-list>

      <span v-show="false">
        <f7-list no-hairlines id="input-retiro">
          <f7-list-input
            class="item-input-with-value"
            floating-label
            outline
            required
            :validate="props.retiro == 1"
            label="Localidad de Retiro"
            type="select"
            name="origen_retiro"
            :value="props.origen_retiro"
            @change="(e) => {recalculatePickupCosts(e)}"
          >

            <option disabled selected value="">Seleccione una Localidad</option>
            <option
              v-for="(ubicacion, index) in props.ubicaciones_retiro"
              :value="ubicacion"
              :key="index"
            >
              {{ ubicacion }}
            </option>
          </f7-list-input>
        </f7-list>
      </span>
    </f7-block>


    <!-- <div v-if="props.submittedBudget">
      <h2 class="title-main-column">¡Gracias por haber cotizado tu envío con Expreso Oro Negro!</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
      <h2 class="title-main-column">¿Te quedó alguna duda?</h2>
    </div>

    <f7-blocks v-if="props.submittedBudget">
      <f7-row class="totals">
        <div class="col-25 xsmall-50 small-50 medium-50">Asesor Comercial #1</div>
        <div class="col-25 xsmall-50 small-50 medium-50">Asesor Comercial #2</div>
        <div class="col-25 xsmall-50 small-50 medium-50">Asesor Comercial #3</div>
        <div class="col-25 xsmall-50 small-50 medium-50">Asesor Comercial #4</div>
      </f7-row>
    </f7-blocks> -->

    <f7-row v-if="!props.submittedBudget" class="nav-buttons">
      <div class="col-20 medium-10">
        <f7-button raised fill color="red" @click="reinitialize">
          <i class="fas fa-undo"></i>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button raised fill @click="backToStepThree">
          <i class="fas fa-chevron-left"></i>
          <span v-show="$f7.device.desktop">&nbsp;&nbsp;Anterior</span>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button :disabled="!props.termsAccepted" raised fill @click="submitBudget">
          <span v-show="$f7.device.desktop">Confirmar&nbsp;&nbsp;</span>
          <i class="fas fa-check"></i>
        </f7-button>
      </div>
    </f7-row>

    <!-- <f7-row v-else class="nav-buttons">
      <div class="col-25"></div>

      <div class="col-100 medium-50">
        <f7-button class="external" :href="props.pdfFilePath" download target="_blank" raised fill>
          Descargar Comprobante
          &nbsp;&nbsp;
          <i class="fas fa-download"></i>
        </f7-button>
      </div>

      <div class="col-25"></div>
    </f7-row> -->
  </div>
</template>

<script>
  import utils from '../utils.js';
  import home from '../home.js';

  export default {
    props: {
      props: Object
    },
    mounted: function() {
      const self = this;
    },
    methods: {
      backToStepTwo() {
        const self = this;
        home.backToStepThree(self);
      },
      // submitStepFourBudget() {
      //   const self = this;
      //   home.submitStepFourBudget(self);
      // },
      backToStepThree() {
        const self = this;
        home.backToStepThree(self);
      },
      submitBudget() {
        const self = this;
        home.submitBudget(self);
      },
      checkCoupon(couponCode) {
        const self = this;
        home.checkCoupon(self, couponCode);
      },
      reinitialize() {
        const self = this;
        home.reinitialize(self);
      },
      recalculatePickupCosts(e) {
        const self = this;

        self.props.origen_retiro = e.currentTarget.value;
        home.recalculatePickupCosts(self, e);
      }
    }
  }

</script>
