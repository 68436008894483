<!-- Products Sheet -->
<template id="products-sheet">
  <f7-sheet
    class="products-sheet"
    :opened="props.productsSheetOpened"
    @sheet:closed="props.productsSheetOpened = false"
    @sheet:close="calculateTotals"
  >
    <f7-toolbar>
      <div class="left">
        <f7-link>Seleccione los productos</f7-link>
      </div>
      <div class="right">
        <f7-link sheet-close>Cerrar</f7-link>
      </div>
    </f7-toolbar>
    <f7-page-content>

      <f7-block style="margin: 0; padding: 10px 5px; background-color: #ffffff; position: sticky; top: 0; z-index: 2;">
        <f7-row>
          <f7-col>
            <f7-searchbar
              class=""
              search-container=".search-list"
              :disable-button="!$theme.aurora"
              placeholder="Escriba para buscar"
              :clear-button="true"
            >
            </f7-searchbar>
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block class="searchbar-not-found">
        <f7-row>
          <f7-col style="width: 100%; text-align: center; font-size: 20px;">
            No se han encontrado resultados
          </f7-col>
        </f7-row>
      </f7-block>

      <f7-block class="no-margin">
        <p>Si el producto que desea agregar no se encuentra en la lista, haga click en el siguiente botón.</p>
        <f7-button raised fill @click="selectOtherProduct">
          <i class="fas fa-question"></i>
          <span>&nbsp;&nbsp;Otro Producto</span>
        </f7-button>
      </f7-block>

      <f7-block>
        <f7-list
          class="virtual-list-products search-list searchbar-found"
          no-hairlines
          media-list
          virtual-list
          :virtual-list-params="{
             items,
             searchAll,
             renderExternal,
             setListHeight: true,
             height: '40'
             }"
        >
          <ul>
            <f7-list-item
              media-item
              v-for="(product, index) in vlData.items"
              :key="index"
              :title="product.name"
              @click="addProduct(product.index_order)"
              class="product-item-sheet"
              v-bind:style="
                'background-color:' + (props.productsIndexesSelectedSheet.indexOf(product.name) > -1 ? '#e1e1e1;' : 'inherit;') +
                'color:' + (props.productsIndexesSelectedSheet.indexOf(product.name) > -1 ? '#7d7d7d;' : 'inherit;')
              "
            >
              <f7-icon slot="media" icon="demo-list-icon">
                <!-- <i class="fas fa-check"></i> -->
                <img :src="$root.frappe_env.frappe_domain + product.imagen" height="24" width="24" >
              </f7-icon>
            </f7-list-item>
          </ul>
        </f7-list>
      </f7-block>

    </f7-page-content>
  </f7-sheet>
</template>

<script>
  import utils from '../utils.js';
  import home from '../home.js';

  export default {
    props: {
      props: Object
    },
    data() {
      return {
        items: [],
        vlData: {
          items: []
        }
      }
    },
    mounted() {
      const self = this;

      setTimeout(function() {
        var virtualListProducts = self.$f7.virtualList.get('.virtual-list-products');
        virtualListProducts.replaceAllItems(self.props.productsFromDb);
    }, 6000);
    },
    methods: {
      addProduct(index) {
        const self = this;
        home.addProduct(self, index);
      },
      calculateTotals() {
        const self = this;
        home.calculateTotals(self);
      },
      renderExternal(vl, vlData) {
        this.vlData = vlData;
        if (vlData.items.length === 0) {
          this.vlData.items = vl.items;
        } else {
          this.vlData = vlData;
        }
      },
      searchAll(query, items) {
        const self = this;
        const found = [];

        for (let i = 0; i < items.length; i += 1) {
          if (items[i].nombre_producto.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') {
            if (!found.includes(i)) {
              found.push(i);
            }
          }
        }
        return found; // return array with mathced indexes
      },
      selectOtherProduct() {
        const self = this;

        self.$f7.dialog.prompt(
          "¿Que producto deseas cotizar?",
          "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
          function(value) {
            home.budgetizeAnotherProduct(self, value);
          },
          function(value) {
            self.$f7.dialog.close();
          }
        );
      }
    }
  }

</script>
