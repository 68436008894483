<!-- Step 1 -->
<template id="step-1">
  <div v-if="props.stepOneBudgetForm" class="main-column">
    <div>
      <f7-swiper
        style="width: 100%; margin-bottom: 20px;"
        v-if="props.sliders.length > 0"
        :params="{
          init: true,
          loop: props.sliders.length > 1 ? true : false,
          autoplay: {
            delay: props.sliders.length > 1 ? 3000 : undefined,
          },
          effect: 'slide',
          speed: 500,
          slidesPerView: 1,
          spaceBetween: 10
        }"
      >
        <f7-swiper-slide
          v-for="(slider, index) in props.sliders"
          :key="index"
          class="swiper-lazy"
        >
          <a class="external" :href="slider.enlace">
            <div class="banner-publicitario image-responsive-container">
              <div class="image-responsive"
                v-bind:style="{
                  'background-image': 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(\'' + $root.frappe_env.frappe_domain + slider.imagen + '\')',
                }"
              ></div>
              <!-- <div class="swiper-lazy-preloader"></div> -->
            </div>
          </a>
        </f7-swiper-slide>
      </f7-swiper>

      <h2 class="title-main-column">Bienvenid@</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
      <p>
        Para comenzar, indicanos algunos datos para poder contactarnos con vos.<br>
      </p>
    </div>

    <form id="stepOneBudgetForm">
      <f7-row>
        <div class="col-100">
          <f7-list no-hairlines class="no-margin">
            <f7-list-input
              floating-label
              outline
              name="solicitante_email"
              label="Email"
              type="email"
              required
              validate
              :pattern="props.solicitante_email_confirmacion"
              :disabled="props.budget_docname !== '' ? true : false"
              error-message="El email ingresado no es válido"
              :value="props.solicitante_email"
              @input="(e) => {props.solicitante_email = e.currentTarget.value}"
              :onValidate="(isValid) => setFormValid(isValid)"
              @blur="(e) => setFormValid(e.srcElement.value === '' ? false : true)"
            >
              <span slot="media">
                <i class="fas fa-envelope icon-form"></i>
              </span>
              <span slot="info"><strong>Obligatorio</strong>. Mediante tu email podremos identificar tu pedido</span>
            </f7-list-input>

            <f7-list-input
              floating-label
              outline
              name="solicitante_email_confirmacion"
              label="Confirmación de Email"
              type="email"
              required
              validate
              :pattern="props.solicitante_email"
              :disabled="props.budget_docname !== '' ? true : false"
              error-message="Los emails ingresados no coinciden"
              :value="props.solicitante_email_confirmacion"
              @input="(e) => {props.solicitante_email_confirmacion = e.currentTarget.value}"
              :onValidate="(isValid) => setFormValid(isValid)"
              @blur="(e) => setFormValid(e.srcElement.value === '' || e.srcElement.value !== props.solicitante_email ? false : true)"
            >
              <span slot="media">
                <i class="fas fa-envelope icon-form"></i>
              </span>
              <span slot="info"><strong>Obligatorio</strong>. Ingresa nuevamente tu email</span>
            </f7-list-input>

            <f7-list-input
              floating-label
              outline
              name="solicitante_whatsapp"
              label="WhatsApp"
              type="text"
              required
              validate
              pattern="^[0-9]{10,}$"
              :disabled="props.budget_docname !== '' ? true : false"
              error-message="El teléfono ingresado no es válido. Recuerde utilizar solo números"
              :value="props.solicitante_whatsapp"
              @input="(e) => {props.solicitante_whatsapp = e.currentTarget.value}"
              :onValidate="(isValid) => setFormValid(isValid)"
              @blur="(e) => setFormValid(e.srcElement.value === '' ? false : true)"
            >
              <span slot="media">
                <i class="fab fa-whatsapp icon-form"></i>
              </span>
              <span slot="info"><strong>Obligatorio</strong>. Nos ayudará a gestionar mas rápido tu cotización. <strong>Formato</strong>: 11 2345 6789</span>
            </f7-list-input>
          </f7-list>
        </div>
      </f7-row>
    </form>

    <f7-row class="nav-buttons">
      <div class="col-20 medium-10">
        <f7-button raised fill color="red" @click="reinitialize">
          <i class="fas fa-undo"></i>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
      </div>

      <div class="col-40 medium-45">
        <f7-button :disabled="!props.stepOneBudgetFormValid" raised fill @click="submitStepOneBudget">
          <span v-show="$f7.device.desktop">Siguiente&nbsp;&nbsp;</span>
          <i class="fas fa-chevron-right"></i>
        </f7-button>
      </div>
    </f7-row>
  </div>
</template>

<script>
  import utils from '../utils.js';
  import home from '../home.js';

  export default {
    props: {
      props: Object
    },
    methods: {
      submitStepOneBudget() {
        const self = this;
        home.submitStepOneBudget(self);
      },
      reinitialize() {
        const self = this;
        home.reinitialize(self);
      },
      setFormValid(isValid) {
        const self = this;
        self.props.stepOneBudgetFormValid = false;

        if (self.props.solicitante_email !== "" &&
            self.props.solicitante_email_confirmacion !== "" &&
            self.props.solicitante_whatsapp !== "" &&
            self.props.solicitante_email === self.props.solicitante_email_confirmacion) {
          self.props.stepOneBudgetFormValid = isValid;
        }
      }
    }
  }

</script>
