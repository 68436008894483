<!-- Step 3 -->
<template id="step-3">
  <div v-if="props.stepThreeBudgetForm" class="main-column">
    <div>
      <h2 class="title-main-column">Selecciona los Productos</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
      <p>Los valores indicados NO incluyen IVA, tampoco se incluyeron los costos de seguros.</p>
      <p>Si el monto de la carga supera el millón de pesos, el porcentaje a cobrar por seguro será del 2% del Valor Declarado.</p>
    </div>

    <f7-row>
      <div v-if="props.products.length > 0" class="col-100 xsmall-100 small-100 products-titles">
        <f7-row style="width: 100%;">
          <div class="col-100 xsmall-100 small-100 medium-60 product-header-table">Producto</div>
          <div class="col-100 xsmall-100 small-100 medium-20 product-header-table">Cantidad</div>
          <div class="col-100 xsmall-100 small-100 medium-20 product-header-table">Costo de Envío</div>
        </f7-row>
      </div>

      <div class="col-100 xsmall-100 small-100">

        <f7-list v-if="props.products.length > 0" no-hairlines style="margin-bottom: 50px;">
          <f7-list-item
            v-for="(product, index) in props.products"
            :key="index"
          >

            <div slot="title" style="white-space: break-spaces !important;">
              <span>{{ product.name }}</span>
            </div>

            <a href="#" slot="media" @click="removeProduct(index, product.index_from_db)">
              <i class="fas fa-trash" style="color: #e30613;"></i>
            </a>

            <span style="display: flex; width: 40%;">
              <f7-list-input
                class="input-qty-sheet-list"
                style="width: 50%;"
                outline
                type="text"
                :default-value="product.qty"
                @input="e => onChangeQty(e, index, product.value_shipping, product.value_pickup)"
              >
              </f7-list-input>

              <f7-list-input
                v-show="$f7.device.desktop"
                class="input-value-sheet-list"
                style="width: 50%;"
                :id="'input-value-' + index"
                outline
                readonly
                disabled
                type="text"
                :value="product.value_shipping"
              >
              </f7-list-input>

              <f7-list-input
                class="input-pickup-sheet-list"
                :id="'input-value-pickup-' + index"
                v-show="false"
                outline
                readonly
                disabled
                type="text"
                :value="product.value_pickup"
              >
              </f7-list-input>
            </span>

            <!-- <div v-show="!$f7.device.desktop">
              <span>Costo de Envío: $ {{ product.value_shipping }}</span>
            </div> -->

          </f7-list-item>
        </f7-list>

        <div style="border: 1px dashed rgb(204, 204, 204); padding: 15px 0px; width: 90%; margin: 30px auto 30px auto; border-radius: 5px;">
          <f7-button large small @click="props.productsSheetOpened = true" style="margin: 0 auto;">
            <i class="fas fa-plus"></i>
            &nbsp;&nbsp;
            Agregar Productos
          </f7-button>
        </div>
      </div>

    </f7-row>

    <f7-row class="totals-row">
      <div class="col-100 medium-33" style="margin: 10px 0">
        <span>Costo de Transporte</span>
        <div class="input-total">
          {{ props.totalShipping.toFixed(2) }}
        </div>
      </div>
      <div class="col-100 medium-33" style="margin: 10px 0">
        <span>Costo de Retiro</span>
        <div class="input-total">
          {{ props.totalPickUp.toFixed(2) }}
        </div>
      </div>
      <div class="col-100 medium-33" style="margin: 10px 0">
        <span>Costo Neto</span>
        <span>(Sin Seguro y Sin IVA)</span>
        <div class="input-total">
          {{ props.total.toFixed(2) }}
        </div>
      </div>
    </f7-row>

    <f7-row class="nav-buttons">
      <div class="col-20 medium-10">
        <f7-button raised fill color="red" @click="reinitialize">
          <i class="fas fa-undo"></i>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button raised fill @click="backToStepTwo">
          <i class="fas fa-chevron-left"></i>
          <span v-show="$f7.device.desktop">&nbsp;&nbsp;Anterior</span>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button raised fill @click="submitStepThreeBudget">
          <span v-show="$f7.device.desktop">Continuar&nbsp;&nbsp;</span>
          <i class="fas fa-chevron-right"></i>
        </f7-button>
      </div>
    </f7-row>
  </div>
</template>

<script>
  import utils from '../utils.js';
  import home from '../home.js';

  export default {
    props: {
      props: Object
    },
    mounted: function() {
      const self = this;
    },
    methods: {
      removeProduct(index, indexFromDb) {
        const self = this;
        home.removeProduct(self, index, indexFromDb);
      },
      backToStepTwo() {
        const self = this;
        home.backToStepTwo(self);
      },
      submitStepThreeBudget() {
        const self = this;
        home.submitStepThreeBudget(self);
      },
      onChangeQty(e, index, value_shipping, value_pickup) {
        const self = this
        home.onChangeQty(self, e, index, value_shipping, value_pickup);
      },
      reinitialize() {
        const self = this;
        home.reinitialize(self);
      },
    }
  }

</script>
