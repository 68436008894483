<template>
  <f7-page name="home">
    <f7-block style="padding: 0; margin: 0;">

      <f7-row class="row-persistent-mobile-full">
        <div class="col-100">
          <div
            class="header-main-column header-main-column-mobile-full"
            v-bind:style="{
              'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
            }"
          >
            <img class="logo-column-persistent" src="static/images/logo-expreso-oro-negro-blanco.png">
            <div>
              <h1 class="title-column-persistent">COTIZÁ AHORA TU ENVÍO</h1>
              <div class="line-column-persistent">//////////////////////////////////////////</div>
              <p class="text-column-persistent">A través de esta herramienta podrá cotizar su carga de forma cómoda y sin perder tiempo.</p>
            </div>
          </div>
        </div>
      </f7-row>

      <f7-row class="row-persistent-mobile-shrinked" style="display: none; position: sticky; z-index: 2; top: 0;">
        <div class="col-100">
          <div
            class="header-main-column header-main-column-mobile-shrinked"
            style="height: 0px; min-height: 75px;"
            v-bind:style="{
              'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
            }"
          >
            <img class="logo-column-persistent" src="static/images/logo-expreso-oro-negro-blanco.png">
            <!-- <div>
              <h1 class="title-column-persistent">COTIZÁ AHORA TU ENVÍO</h1>
              <div class="line-column-persistent">//////////////////////////////////////////</div>
              <p class="text-column-persistent">A través de esta herramienta podrá cotizar su carga de forma cómoda y sin perder tiempo.</p>
            </div> -->
          </div>
        </div>
      </f7-row>

      <f7-row>
        <div
          class="left-column-persistent-on-desktop col-40"
          v-bind:style="{
            'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
          }"
          >
          <img class="logo-column-persistent" src="static/images/logo-expreso-oro-negro-blanco.png">
          <f7-swiper
            style="width: 100%; margin-bottom: 20px;"
            v-if="props.slidersSidebar.length > 0"
            :params="{
              init: true,
              loop: props.slidersSidebar.length > 1 ? true : false,
              autoplay: {
                delay: props.slidersSidebar.length > 1 ? 3000 : undefined,
              },
              effect: 'slide',
              speed: 500,
              slidesPerView: 1,
              spaceBetween: 10
            }"
          >
            <f7-swiper-slide
              v-for="(slider, index) in props.slidersSidebar"
              :key="index"
              class="swiper-lazy"
            >
              <a class="external" :href="slider.enlace">
                <div class="banner-publicitario-sidebar image-responsive-container-sidebar">
                  <div class="image-responsive-sidebar"
                    v-bind:style="{
                      'background-image': 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(\'' + $root.frappe_env.frappe_domain + slider.imagen + '\')',
                    }"
                  >
                  </div>
                  <div class="swiper-lazy-preloader"></div>
                </div>
              </a>
            </f7-swiper-slide>
          </f7-swiper>
          <div>
            <h1 class="title-column-persistent">COTIZÁ AHORA TU ENVÍO</h1>
            <div class="line-column-persistent">//////////////////////////////////////////</div>
            <p class="text-column-persistent">A través de esta herramienta podrá cotizar su carga de forma cómoda y sin perder tiempo.</p>
          </div>
          <span v-show="props.step === 0"></span>
          <p v-show="props.step > 0" style="margin-top: 0; margin-bottom: 0;">
            <span style="text-align: center; width: 100%; display: block; color: #fff; margin-bottom: 10px;">{{ "Paso " + props.step + " de 4" }}</span>
            <f7-progressbar class="progress-column-persistent-on-desktop" :progress="props.step / 4 * 100"></f7-progressbar>
          </p>
        </div>

        <div class="container-main-column col-100 xsmall-100 small-100 medium-100 large-60">

          <step1 :props="props" />
          <step2 :props="props" />
          <step3 :props="props" />
          <step4 :props="props" />
          <step5 :props="props" />
        </div>
      </f7-row>
    </f7-block>

    <productsSheet :props="props" />

  </f7-page>
</template>


<script>
  import utils from '../utils.js';
  import home from '../home.js';
  import productsSheet from '../components/products-sheet.vue';
  import step1 from '../components/step-1.vue';
  import step2 from '../components/step-2.vue';
  import step3 from '../components/step-3.vue';
  import step4 from '../components/step-4.vue';
  import step5 from '../components/step-5.vue';

  export default {
    components: {
      productsSheet,
      step1,
      step2,
      step3,
      step4,
      step5,
    },
    data() {
      return {
        props: {
          productsFromDb: [],
          productsSheetOpened: false,
          productsIndexesSelectedSheet: [],
          products: [],
          ubicaciones: [],
          ubicacionesOrigen: [],
          ubicacionesDestino: [],
          ubicaciones_retiro: [],
          stepOneBudgetForm: true,
          stepOneBudgetFormValid: false,
          stepTwoBudgetForm: false,
          stepTwoBudgetFormValid: false,
          stepThreeBudgetForm: false,
          stepFourBudgetForm: false,
          stepFiveBudgetForm: false,
          solicitante_email: "",
          solicitante_confirmacion: "",
          solicitante_whatsapp: "",
          origen: "",
          retiro: 0,
          origen_retiro: "",
          destino: "",
          budget_docname: "",
          step: 1,
          totalShipping: 0.0,
          totalPickUp: 0.0,
          totalDescuento: 0.0,
          total: 0.0,
          termsAccepted: false,
          submittedBudget: false,
          pdfFilePath: "",
          pdfRelativeFilePath: "",
          sliders: [],
          slidersSidebar: [],
          coupon: "",
          coupon_applied: false,
        },
      }
    },
    mounted: function() {
      const self = this;

      if (self.$root.app_is_prod || self.$root.app_staging) {
        self.$root.frappe_env.frappe_domain = self.$root.frappe_url_prod;
      } else {
        self.$root.frappe_env.frappe_domain = self.$root.frappe_url_dev;
      }

      home.loadInitialData(self);
      window.localStorage.removeItem("products");

    let page = self.$$('.page-content');
    let localPage = self.$$('.page[data-name=home]>.page-content')
    // let localPage = self.$$('.page-content>.block');

    page.on('scroll', () => {
      // console.log(localPage[0].scrollTop) // will show page top position
      if (localPage[0].scrollTop > 275) {
        // self.$$(".row-persistent-mobile-full").css("display", "none");
        self.$$(".row-persistent-mobile-shrinked").css("display", "block");
      } else if (localPage[0].scrollTop < 275) {
        self.$$(".row-persistent-mobile-shrinked").css("display", "none");
        // self.$$(".row-persistent-mobile-full").css("display", "unset");
      }
    });
    },
    updated: function() {
      const self = this;
    },
    methods: {
    }
  }
</script>




<!-- Top Navbar -->
<!-- <f7-navbar large :sliding="false">
  <f7-nav-left>
    <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
  </f7-nav-left>
  <f7-nav-title sliding>Cotizador Oro Negro</f7-nav-title>
  <f7-nav-right>
    <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
  </f7-nav-right>
  <f7-nav-title-large>Cotizador Oro Negro</f7-nav-title-large>
</f7-navbar> -->
<!-- Toolbar-->
<!-- <f7-toolbar bottom>
  <f7-link>Left Link</f7-link>
  <f7-link>Right Link</f7-link>
</f7-toolbar> -->
<!-- Page content-->
<!-- <f7-block strong>
  <p>Here is your blank Framework7 app. Let's see what we have here.</p>
</f7-block>
<f7-block-title>Navigation</f7-block-title>
<f7-list>
  <f7-list-item link="/about/" title="About"></f7-list-item>
  <f7-list-item link="/form/" title="Form"></f7-list-item>
</f7-list>

<f7-block-title>Modals</f7-block-title>
<f7-block strong>
  <f7-row>
    <f7-col width="50">
      <f7-button fill raised popup-open="#my-popup">Popup</f7-button>
    </f7-col>
    <f7-col width="50">
      <f7-button fill raised login-screen-open="#my-login-screen">Login Screen</f7-button>
    </f7-col>
  </f7-row>
</f7-block>

<f7-block-title>Panels</f7-block-title>
<f7-block strong>
  <f7-row>
    <f7-col width="50">
      <f7-button fill raised panel-open="left">Left Panel</f7-button>
    </f7-col>
    <f7-col width="50">
      <f7-button fill raised panel-open="right">Right Panel</f7-button>
    </f7-col>
  </f7-row>
</f7-block>

<f7-list>
  <f7-list-item
    title="Dynamic (Component) Route"
    link="/dynamic-route/blog/45/post/125/?foo=bar#about"
  ></f7-list-item>
  <f7-list-item
    title="Default Route (404)"
    link="/load-something-that-doesnt-exist/"
  ></f7-list-item>
  <f7-list-item
    title="Request Data & Load"
    link="/request-and-load/user/123456/"
  ></f7-list-item>
</f7-list> -->
