import utils from './utils.js';

var loadInitialData = function(self) {
  var loadInitialDataCallback = function(e) {
    self.props.ubicaciones_retiro = e.message.data.ubicaciones_retiro;
    self.props.ubicaciones = e.message.data.ubicaciones;
    self.props.ubicacionesOrigen = e.message.data.ubicaciones_origen.slice();
    self.props.ubicacionesDestino = e.message.data.ubicaciones_destino.slice();
    self.props.productsFromDb = e.message.data.productos;
    self.props.sliders = e.message.data.sliders;
    self.props.slidersSidebar = e.message.data.sliders_sidebar;
  };

  utils.customRequest(
    self,
    loadInitialDataCallback,
    'utils.get_initial_data',
    'POST',
    {},
    ''
  );
};

var submitStepOneBudget = function(self) {
  if (self.props.budget_docname === "") {
    var formData = self.$f7.form.convertToData('#stepOneBudgetForm');
    var formHasEmptyValues = false;
    var formLenght = Object.keys(formData).length;
    var formValues = Object.values(formData);

    var sendSetpOneFormCallback = function(e) {
      self.props.budget_docname = e.message.data.docname;
      self.props.stepOneBudgetForm = false;
      self.props.stepTwoBudgetForm = true;
      self.props.step = 2;

      document.querySelector(".main-column").scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    };

    var counter = 0;
    for (var item in formData) {
      if (!formHasEmptyValues && item === "solicitante_email" && formData[item] === "") {
        formHasEmptyValues = true;
      } else if (!formHasEmptyValues && item === "solicitante_email_confirmacion" && formData[item] === "") {
        formHasEmptyValues = true;
      } else if (!formHasEmptyValues && item === "solicitante_whatsapp" && formData[item] === "") {
        formHasEmptyValues = true;
      }

      if (formLenght === counter + 1) {
        if (formHasEmptyValues) {
          self.$f7.dialog.alert("Debes completar tu email para comenzar");
        } else {
          utils.customRequest(
            self,
            sendSetpOneFormCallback,
            'pedido_de_cotizacion.pedido_de_cotizacion.create_new_budget',
            'POST',
            {
              form_data: formData
            },
            ''
          );
        }
      }

      counter++;
    }
  } else {
    self.props.stepOneBudgetForm = false;
    self.props.stepTwoBudgetForm = true;
    self.props.step = 2;
  }
};

var submitStepTwoBudget = function(self) {
  var formData = self.$f7.form.convertToData('#stepTwoBudgetForm');
  var formHasEmptyValues = false;
  var formLenght = Object.keys(formData).length;
  var formValues = Object.values(formData);

  if (self.props.origen === self.props.destino) {
    self.$f7.dialog.create({
      title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
      text: "La ciudad de <strong>Origen</strong> y de <strong>Destino</strong> deben ser diferentes",
      buttons: [
        {
          text: "Volver",
          cssClass: "btn-dialog-cancel",
          onClick: function(dialog, e) {
            self.$f7.dialog.close();
          }
        }
      ]
    }).open();
  } else {
    var sendSetpTwoFormCallback = function(e) {
      self.props.stepTwoBudgetForm = false;
      self.props.stepThreeBudgetForm = true;
      self.props.step = 3;

      document.querySelector(".main-column").scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    };

    var counter = 0;
    for (var item in formData) {
      if (!formHasEmptyValues && item === "origen" && formData[item] === "") {
        formHasEmptyValues = true;
      } else if (!formHasEmptyValues && item === "destino" && formData[item] === "") {
        formHasEmptyValues = true;
      } else if (!formHasEmptyValues && self.props.retiro === 1 && item === "origen_retiro" && formData[item] === "") {
        formHasEmptyValues = true;
      }

      if (formLenght === counter + 1) {
        if (formHasEmptyValues) {
          self.$f7.dialog.alert("Debes completar los datos requeridos");
        } else {
          utils.customRequest(
            self,
            sendSetpTwoFormCallback,
            'pedido_de_cotizacion.pedido_de_cotizacion.update_destination_new_budget',
            'POST',
            {
              budget_docname: self.props.budget_docname,
              form_data: formData
            },
            ''
          );
        }
      }

      counter++;
    }
  }
};

var submitStepThreeBudget = function (self) {
  var productsLS = window.localStorage.getItem("products");

  if (productsLS === null) {
    self.$f7.dialog.alert("Agregue productos a su cotización para continuar.");
  } else {
    var sendSetpThreeFormCallback = function(e) {
      self.props.stepThreeBudgetForm = false;
      self.props.stepFourBudgetForm = true;
      self.props.step = 4;
      self.props.totalShipping = e.message.data.total_costo_de_transporte;
      self.props.totalPickUp = e.message.data.total_costo_de_retiro;
      self.props.total = e.message.data.total_final;

      document.querySelector(".main-column").scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    };

    utils.customRequest(
      self,
      sendSetpThreeFormCallback,
      'pedido_de_cotizacion.pedido_de_cotizacion.update_products_new_budget',
      'POST',
      {
        budget_docname: self.props.budget_docname,
        products: productsLS
      },
      ''
    );
  }
};

var submitBudget = function (self) {
  var popupRetiro = self.$f7.dialog.create({
    title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
    text: `
      Si lo desea, puede elegir una localidad para que realicemos el retiro en domicilio de la mercadería.
       El costo de retiro se calculará automáticamente al seleccionar una localidad.
      <br>
      <div id="popup-retiro-input"></div>
      <div id="popup-retiro-value-text"></div>
    `,
    cssClass: "popup-retiro",
    buttons: [
      {
        text: "No, Gracias",
        cssClass: "btn-dialog-cancel",
        onClick: function(dialog, e) {
          self.$f7.dialog.close();
          completeBudgetProcess(self);
        }
      },
      {
        text: "Si, Agregar",
        cssClass: "btn-dialog-cancel",
        onClick: function(dialog, e) {
          var productsLS = window.localStorage.getItem("products");

          var changeProductsInBudgetCallback = function(e) {
            self.props.totalShipping = e.message.data.total_costo_de_transporte;
            self.props.totalPickUp = e.message.data.total_costo_de_retiro;
            self.props.total = e.message.data.total_final;
            completeBudgetProcess(self);
          };

          utils.customRequest(
            self,
            changeProductsInBudgetCallback,
            'pedido_de_cotizacion.pedido_de_cotizacion.update_products_with_pickup_costs',
            'POST',
            {
              budget_docname: self.props.budget_docname,
              products: productsLS,
              origen_retiro: self.props.origen_retiro
            },
            ''
          );
        }
      }
    ]
  });

  if (!self.props.origen_retiro) {
    popupRetiro.open();
    var popupRetiroInput = self.$$("#popup-retiro-input")[0];
    var retiroInput = self.$$("#input-retiro").appendTo(popupRetiroInput);
  } else {
    completeBudgetProcess(self);
  }
}

var recalculatePickupCosts = function(self) {
  var productsLS = JSON.parse(window.localStorage.getItem("products"));
  var totalPickupValue = 0.0;

  productsLS.forEach((item, i) => {
    var getValueProductsCallback = function(e) {
      // productToAdd.index_from_db = index;
      // productToAdd.qty = 1;
      var value_shipping = e.message.data.value_shipping.costo;
      var value_pickup = e.message.data.value_pickup.costo;
      // self.props.productsIndexesSelectedSheet.push(productToAdd.name);

      item.value_pickup = value_pickup * item.qty;
      totalPickupValue += value_pickup * item.qty;

      // if (productsLS === null) {
      //   window.localStorage.setItem("products", JSON.stringify([productToAdd]));
      //   self.props.products = [productToAdd];
      // } else {
      //   var finalProducts = JSON.parse(productsLS);
      //   finalProducts.push(productToAdd);
      //   window.localStorage.setItem("products", JSON.stringify(finalProducts));
      //   self.props.products = finalProducts;
      // }
      if (productsLS.length === i + 1) {
        window.localStorage.setItem("products", JSON.stringify(productsLS));
        self.$$("#popup-retiro-value-text").text(`Se sumará a su presupuesto $ ${ totalPickupValue.toFixed(2) }`);

      }
    };

    utils.customRequest(
      self,
      getValueProductsCallback,
      'utils.get_values_for_product',
      'POST',
      {
        "ubicacion_origen": self.props.origen,
        "ubicacion_destino": self.props.destino,
        "ubicacion_origen_retiro": self.props.origen_retiro,
        "categoria": item.categoria,
      },
      ''
    );
  });
}

var completeBudgetProcess = function(self) {
  self.$f7.dialog.progress("Confirmando Cotización", undefined, "#253858");

  var submitBudgetCallback = function(e) {
    self.props.stepFourBudgetForm = false;
    self.props.stepFiveBudgetForm = true;
    self.props.step = 0;
    self.props.submittedBudget = true;
    self.props.pdfFilePath = e.message.data.pdf_file_path;
    self.props.pdfRelativeFilePath = e.message.data.pdf_relative_file_path;
    self.$f7.dialog.close();

    document.querySelector(".main-column").scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });

    sendReceiptByEmail(self);

    if (self.props.origen_retiro) {
      setTimeout(function() {
        self.$f7.dialog.create({
          title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
          text: "¿Le gustaría que nuestro servicio de atención al cliente se comunique con usted para confirmar la solicitud de retiro?",
          buttons: [
            {
              text: "No",
              cssClass: "btn-dialog-cancel",
              onClick: function(dialog, e) {
                self.$f7.dialog.close();
              }
            },
            {
              text: "Si",
              cssClass: "btn-dialog-cancel",
              onClick: function(dialog, e) {
                contactToPickup(self);
              }
            }
          ]
        }).open();
      }, 2000);
    }
  };

  utils.customRequest(
    self,
    submitBudgetCallback,
    'pedido_de_cotizacion.pedido_de_cotizacion.confirm_new_budget',
    'POST',
    {
      budget_docname: self.props.budget_docname,
      origen_retiro: self.props.origen_retiro
    },
    ''
  );
}

var contactToPickup = function(self) {
  var contactToPickupCallback = function(e) {
  }

  utils.customRequest(
    self,
    contactToPickupCallback,
    'pedido_de_cotizacion.pedido_de_cotizacion.contact_to_pickup',
    'POST',
    {
      budget_docname: self.props.budget_docname,
      pdf_receipt: self.props.pdfRelativeFilePath,
      origen_retiro: self.props.origen_retiro
    },
    ''
  );
}

var submitStepFourBudget = function (self) {
  var formData = self.$f7.form.convertToData('#stepFourBudgetForm');
  var formHasEmptyValues = false;
  var formLenght = Object.keys(formData).length;
  var formValues = Object.values(formData);

  var sendSetpOneFormCallback = function(e) {
    self.$f7.dialog.alert("Su cotización se ha enviado exitosamente");
    self.$f7.views.main.router.refreshPage();
  };

  formValues.forEach(function(item, i) {
    if (!formHasEmptyValues && item === "") {
      formHasEmptyValues = true;
    }

    if (formLenght === i + 1) {
      if (formHasEmptyValues) {
        self.$f7.dialog.alert("Debe completar todos los campos");
      } else {
        // utils.customRequest(
        //   self,
        //   sendSetpOneFormCallback,
        //   'pedido_de_cotizacion.pedido_de_cotizacion.update_new_budget',
        //   'POST',
        //   {
        //     budget_docname: self.budget_docname,
        //     form_data: formData
        //   },
        //   ''
        // );
      }
    }
  });
};

var sendReceiptByEmail = function(self) {
  var sendReceiptByEmailCallback = function(e) {
  }

  utils.customRequest(
    self,
    sendReceiptByEmailCallback,
    'pedido_de_cotizacion.pedido_de_cotizacion.send_receipt_by_email',
    'POST',
    {
      budget_docname: self.props.budget_docname,
      pdf_receipt: self.props.pdfRelativeFilePath
    },
    ''
  );
};

var backToStepOne = function(self) {
  self.props.stepOneBudgetForm = true;
  self.props.stepTwoBudgetForm = false;
  self.props.step = 1;
};

var backToStepTwo = function (self) {
  self.props.stepTwoBudgetForm = true;
  self.props.stepThreeBudgetForm = false;
  self.props.step = 2;
};

var backToStepThree = function (self) {
  self.props.stepThreeBudgetForm = true;
  self.props.stepFourBudgetForm = false;
  self.props.step = 3;
};

var onChangeRetiroSelected = function(self) {
  if (self.props.retiro == 0) {
    self.props.retiro = 1;
  } else {
    self.props.retiro = 0;
    self.props.origen_retiro = "";
  }
};

var addProduct = function (self, index) {
  var productToAdd = self.props.productsFromDb[index];

  if (self.props.productsIndexesSelectedSheet.indexOf(productToAdd.name) > -1) {
    return;
  }
  // else if (productToAdd.nombre_producto === "Otro") {
  //   self.$f7.dialog.prompt(
  //     "¿Que producto deseas cotizar?",
  //     "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
  //     function(value) {
  //       budgetizeAnotherProduct(self, value);
  //     },
  //     function(value) {
  //       self.$f7.dialog.close();
  //     }
  //   );
  // }
  else {
    var getValueProductsCallback = function(e) {
      productToAdd.index_from_db = index;
      productToAdd.qty = 1;
      productToAdd.value_shipping = e.message.data.value_shipping.costo;
      productToAdd.value_pickup = e.message.data.value_pickup.costo;
      self.props.productsIndexesSelectedSheet.push(productToAdd.name);

      var productsLS = window.localStorage.getItem("products");

      if (productsLS === null) {
        window.localStorage.setItem("products", JSON.stringify([productToAdd]));
        self.props.products = [productToAdd];
      } else {
        var finalProducts = JSON.parse(productsLS);
        finalProducts.push(productToAdd);
        window.localStorage.setItem("products", JSON.stringify(finalProducts));
        self.props.products = finalProducts;
      }
    };

    utils.customRequest(
      self,
      getValueProductsCallback,
      'utils.get_values_for_product',
      'POST',
      {
        "ubicacion_origen": self.props.origen,
        "ubicacion_destino": self.props.destino,
        "ubicacion_origen_retiro": self.props.origen_retiro,
        "categoria": productToAdd.categoria,
      },
      ''
    );
  }
};

var budgetizeAnotherProduct = function(self, value) {
  var budgetizeAnotherProductCallback = function(e) {
    self.$f7.dialog.create({
      title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
      text: "Te estamos redirigiendo a nuestro Cotizador Estandar.<br><br>¿Deseas Continuar?",
      buttons: [
        {
          text: "No, Volver",
          cssClass: "btn-dialog-cancel",
          onClick: function(dialog, e) {
            self.$f7.dialog.close();
          }
        },
        {
          text: "Si, Continuar",
          cssClass: "btn-dialog-accept",
          onClick: function(dialog, e) {
            closeActualBudget(self);
          }
        }
      ]
    }).open();
  }

  utils.customRequest(
    self,
    budgetizeAnotherProductCallback,
    'producto_sin_categoria.producto_sin_categoria.presupuestar_otro',
    'POST',
    {
      "producto_a_cotizar": value,
      "budget_docname": self.props.budget_docname
    },
    ''
  );
}

var closeActualBudget = function(self) {
  var closeActualBudgetCallback = function(e) {
    window.location.replace("https://expresooronegro.com/cotizador/");
  }

  utils.customRequest(
    self,
    closeActualBudgetCallback,
    'producto_sin_categoria.producto_sin_categoria.cerrar_presupuesto_actual',
    'POST',
    {
      "budget_docname": self.props.budget_docname
    },
    ''
  );
}

var onChangeQty = function(self, e, index, value_shipping, value_pickup) {
  var qtyValue = e.srcElement.value !== "" ? e.srcElement.value : 0;

  var valueField = self.$$(`#input-value-${index}`).find("input");
  valueField[0].value = (parseInt(qtyValue) * value_shipping).toFixed(2);

  var valuePickUpField = self.$$(`#input-value-pickup-${index}`).find("input");
  valuePickUpField[0].value = (parseInt(qtyValue) * value_pickup).toFixed(2);  // (value_pickup).toFixed(2);

  self.props.products[index].qty = qtyValue;

  var productsLS = window.localStorage.getItem("products");
  var finalProducts = JSON.parse(productsLS);

  finalProducts[index].qty = qtyValue;

  window.localStorage.setItem("products", JSON.stringify(finalProducts));

  calculateTotals(self);
}

var calculateTotals = function(self) {
  var valueShippingFields = self.$$(`.input-value-sheet-list`);
  var valuePickUpFields = self.$$(`.input-pickup-sheet-list`);

  self.props.totalShipping = 0.0;
  self.props.totalPickUp = 0.0;
  self.props.total = 0.0;

  if (valueShippingFields.length > 0) {
    valueShippingFields.forEach(function(item, i) {
      var valueShippingField = self.$$(`#${item.id}`).find("input")[0].value;
      self.props.totalShipping += parseFloat(valueShippingField);

      if (valueShippingFields.length === i + 1) {
        if (valuePickUpFields.length > 0) {
          valuePickUpFields.forEach(function(item, i) {
            var valuePickUpField = self.$$(`#${item.id}`).find("input")[0].value;
            self.props.totalPickUp += parseFloat(valuePickUpField);

            if (valuePickUpFields.length === i + 1) {
              self.props.total = self.props.totalShipping + self.props.totalPickUp
            }
          });
        }
      }
    });
  }
}

var removeProduct = function(self, index, indexFromDb) {
  self.props.productsIndexesSelectedSheet.splice(self.props.productsIndexesSelectedSheet.indexOf(indexFromDb), 1);

  var productsLS = window.localStorage.getItem("products");
  var finalProducts = JSON.parse(productsLS);

  var splcedEl = finalProducts.splice(index, 1);

  window.localStorage.setItem("products", JSON.stringify(finalProducts));
  self.props.products = finalProducts;

  setTimeout(function() {
    calculateTotals(self);
  }, 50);
};

var checkCoupon = function(self) {
  var checkCouponCallback = function(e) {
    if (e.message.success === true) {
      self.props.coupon_applied = true;
      self.props.total = e.message.data.total_final;
      self.props.totalDescuento = e.message.data.total_descuento

      self.$f7.dialog.create({
        title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
        text: "Su cupón ha sido aplicado con éxito",
        buttons: [
          {
            text: "Volver",
            cssClass: "btn-dialog-cancel",
            onClick: function(dialog, e) {
              self.$f7.dialog.close();
            }
          }
        ]
      }).open();
    } else {
      self.$f7.dialog.create({
        title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
        text: e.message.message,
        buttons: [
          {
            text: "Volver",
            cssClass: "btn-dialog-cancel",
            onClick: function(dialog, e) {
              self.$f7.dialog.close();
            }
          }
        ]
      }).open();
    }
  }

  if (self.props.coupon_applied) {
    self.$f7.dialog.create({
      title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
      text: "Su cupón ya ha sido aplicado",
      buttons: [
        {
          text: "Volver",
          cssClass: "btn-dialog-cancel",
          onClick: function(dialog, e) {
            self.$f7.dialog.close();
          }
        }
      ]
    }).open();
  } else {
    utils.customRequest(
      self,
      checkCouponCallback,
      'cupon.cupon.verificar_cupon',
      'POST',
      {
        "budget_docname": self.props.budget_docname,
        "codigo_cupon": self.props.coupon
      },
      ''
    );
  }
}

var reinitialize = function(self) {
  self.$f7.dialog.create({
    title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
    text: "Se reiniciaran todos los campos de la cotización.<br><br>¿Desea Continuar?",
    buttons: [
      {
        text: "No, Volver",
        cssClass: "btn-dialog-cancel",
        onClick: function(dialog, e) {
          self.$f7.dialog.close();
        }
      },
      {
        text: "Si, Reiniciar",
        cssClass: "btn-dialog-accept",
        onClick: function(dialog, e) {
          self.$f7.views.main.router.refreshPage();
        }
      }
    ]
  }).open();
}

export default {loadInitialData, submitStepOneBudget, submitStepTwoBudget,
  submitStepThreeBudget, backToStepOne, backToStepTwo, backToStepThree,
  addProduct, removeProduct, onChangeRetiroSelected, onChangeQty,
  calculateTotals, submitBudget, reinitialize, checkCoupon, contactToPickup,
  sendReceiptByEmail, budgetizeAnotherProduct, recalculatePickupCosts,
  completeBudgetProcess}
