var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-page',{attrs:{"name":"home"}},[_c('f7-block',{staticStyle:{"padding":"0","margin":"0"}},[_c('f7-row',{staticClass:"row-persistent-mobile-full"},[_c('div',{staticClass:"col-100"},[_c('div',{staticClass:"header-main-column header-main-column-mobile-full",style:({
            'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
          })},[_c('img',{staticClass:"logo-column-persistent",attrs:{"src":"static/images/logo-expreso-oro-negro-blanco.png"}}),_vm._v(" "),_c('div',[_c('h1',{staticClass:"title-column-persistent"},[_vm._v("COTIZÁ AHORA TU ENVÍO")]),_vm._v(" "),_c('div',{staticClass:"line-column-persistent"},[_vm._v("//////////////////////////////////////////")]),_vm._v(" "),_c('p',{staticClass:"text-column-persistent"},[_vm._v("A través de esta herramienta podrá cotizar su carga de forma cómoda y sin perder tiempo.")])])])])]),_vm._v(" "),_c('f7-row',{staticClass:"row-persistent-mobile-shrinked",staticStyle:{"display":"none","position":"sticky","z-index":"2","top":"0"}},[_c('div',{staticClass:"col-100"},[_c('div',{staticClass:"header-main-column header-main-column-mobile-shrinked",staticStyle:{"height":"0px","min-height":"75px"},style:({
            'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
          })},[_c('img',{staticClass:"logo-column-persistent",attrs:{"src":"static/images/logo-expreso-oro-negro-blanco.png"}})])])]),_vm._v(" "),_c('f7-row',[_c('div',{staticClass:"left-column-persistent-on-desktop col-40",style:({
          'background-image': 'linear-gradient(rgba(37, 56, 88, 0.8), rgba(37, 56, 88, 0.8)), url(\'https://binar.io/wp-content/uploads/2020/05/foto-principal-webDSC_5680-2.jpg\')'
        })},[_c('img',{staticClass:"logo-column-persistent",attrs:{"src":"static/images/logo-expreso-oro-negro-blanco.png"}}),_vm._v(" "),(_vm.props.slidersSidebar.length > 0)?_c('f7-swiper',{staticStyle:{"width":"100%","margin-bottom":"20px"},attrs:{"params":{
            init: true,
            loop: _vm.props.slidersSidebar.length > 1 ? true : false,
            autoplay: {
              delay: _vm.props.slidersSidebar.length > 1 ? 3000 : undefined,
            },
            effect: 'slide',
            speed: 500,
            slidesPerView: 1,
            spaceBetween: 10
          }}},_vm._l((_vm.props.slidersSidebar),function(slider,index){return _c('f7-swiper-slide',{key:index,staticClass:"swiper-lazy"},[_c('a',{staticClass:"external",attrs:{"href":slider.enlace}},[_c('div',{staticClass:"banner-publicitario-sidebar image-responsive-container-sidebar"},[_c('div',{staticClass:"image-responsive-sidebar",style:({
                    'background-image': 'linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(\'' + _vm.$root.frappe_env.frappe_domain + slider.imagen + '\')',
                  })}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})])])])}),1):_vm._e(),_vm._v(" "),_c('div',[_c('h1',{staticClass:"title-column-persistent"},[_vm._v("COTIZÁ AHORA TU ENVÍO")]),_vm._v(" "),_c('div',{staticClass:"line-column-persistent"},[_vm._v("//////////////////////////////////////////")]),_vm._v(" "),_c('p',{staticClass:"text-column-persistent"},[_vm._v("A través de esta herramienta podrá cotizar su carga de forma cómoda y sin perder tiempo.")])]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.step === 0),expression:"props.step === 0"}]}),_vm._v(" "),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.props.step > 0),expression:"props.step > 0"}],staticStyle:{"margin-top":"0","margin-bottom":"0"}},[_c('span',{staticStyle:{"text-align":"center","width":"100%","display":"block","color":"#fff","margin-bottom":"10px"}},[_vm._v(_vm._s("Paso " + _vm.props.step + " de 4"))]),_vm._v(" "),_c('f7-progressbar',{staticClass:"progress-column-persistent-on-desktop",attrs:{"progress":_vm.props.step / 4 * 100}})],1)],1),_vm._v(" "),_c('div',{staticClass:"container-main-column col-100 xsmall-100 small-100 medium-100 large-60"},[_c('step1',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('step2',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('step3',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('step4',{attrs:{"props":_vm.props}}),_vm._v(" "),_c('step5',{attrs:{"props":_vm.props}})],1)])],1),_vm._v(" "),_c('productsSheet',{attrs:{"props":_vm.props}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }