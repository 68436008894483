var customRequest = function(self, callback_fn, endpoint, method, data, preloader=null) {
  var hostBase = self.$root.frappe_env.frappe_domain;
  // var hostBase = "https://erp.expresooronegro.reppli.com";
  var host = `${hostBase}/api/method/erp_logistica.erp_logistica.doctype.`;
  var hostRaw = `${hostBase}/api/method/`;

  self.$f7.request({
    url: host + endpoint,
    async: true,
    method: method,
    headers: {
      // 'Authorization': window.localStorage.getItem('token')
      // 'Authorization': 'token f7d26c689c522ff:8c77336f79fe220'
    },
    contentType: 'application/json',
    data: data,
    dataType: 'json',
    beforeSend: function(hxr) {
      if (preloader != null) {
        if (preloader != '') {
          self.$f7.dialog.preloader(preloader);
        }
        self.loading = true;
      }
    },
    success: function(e) {
      // console.log(e.message);

      callback_fn(e);

      if (preloader != null) {
        if (preloader != '') {
          self.$f7.dialog.close();
        }
        self.loading = false;
      }
    },
    error: function(e) {
    }
  })
}

var isEmpty = function(element) {
    if (element === "") {
        return true
    } else if (element === undefined) {
        return true
    } else if (element === null) {
        return true
    } else {
        return false
    }
}

var toastOffline = function(app) {
  var toastBottom = app.toast.create({
    text: "Su dispositivo no tiene conexión",
    position: 'bottom',
    // closeTimeout: 3000,
    cssClass: 'toast-error'
  });
  toastBottom.open();
}

var toastOnline = function(app) {
  var toastBottom = app.toast.create({
    text: "Su dispositivo tiene conexión",
    position: 'bottom',
    closeTimeout: 3000,
    cssClass: 'toast-success'
  });
  toastBottom.open();
}

var checkConnectiviy = function(self) {
  document.addEventListener("online", function() {
    if (JSON.parse(window.localStorage.getItem("online")) === false) {
      self.params.online = true;
      window.localStorage.setItem("online", true);

      self.toast.close();
      toastOnline(self);

      self.router.navigate(self.view.current.router.url, {
        ignoreCache: true,
        reloadCurrent: true
      });
    }
  }, false);

  document.addEventListener("offline", function() {
      self.params.online = false;
      window.localStorage.setItem("online", false);

      toastOffline(self);
  }, false);
}

function create_UUID() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

var toast = function(self, message, status, timeout=3000) {
  var renderToast = self.$f7.toast.create({
    text: message,
    position: 'top',
    closeTimeout: timeout,
    cssClass: status
  });
  renderToast.open();
};

var enterKeySubmit = function(self) {
  if (event.keyCode === 13) {
    event.preventDefault();
    // self.$$();
  }
};

export default {customRequest, isEmpty, toastOnline, toastOffline,
  checkConnectiviy, create_UUID, toast, enterKeySubmit}
