<!-- Step 5 -->
<template id="step-5">
  <div v-if="props.stepFiveBudgetForm" class="main-column">
    <div style="text-align: center;">
      <h2 class="title-main-column">¡Gracias por haber cotizado tu envío con nosotros!</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
    </div>

    <f7-block>
      <f7-row style="margin: 40px 0 40px 0;">
        <div class="col-25"></div>

        <div class="col-100 medium-50">
          <f7-button class="external" :href="props.pdfFilePath" download target="_blank" raised fill>
            Descargar Comprobante
            &nbsp;&nbsp;
            <i class="fas fa-download"></i>
          </f7-button>
          <span v-if="props.origen_retiro === ''" style="display: block; text-align: center; margin-top: 10px;">
            Podés acercarte con este comprobante a la sucursal más cercana para completar tu envío
          </span>
          <br>
          <br>
          <f7-button class="external" :href="$root.frappe_env.frappe_domain + '/files/2018%20Guia%20de%20Embalaje%20Oro%20Negro.pdf'" download target="_blank" raised fill>
            Descargar Guía de Embalaje
            &nbsp;&nbsp;
            <i class="fas fa-download"></i>
          </f7-button>
        </div>

        <div class="col-25"></div>
      </f7-row>
    </f7-block>

    <f7-block style="text-align: center; border: 1px dashed #222; border-radius: 5px; padding: 20px;">
      <h2>¿Te quedó alguna duda? Contactanos.</h2>
      <f7-row>
        <div class="col-50 medium-25 thankyou-page-asesor">
          <a href="https://api.whatsapp.com/send?phone=541122640003" class="external link-nolink">
            <i class="fab fa-whatsapp thankyou-page-icons"></i>
            <br>
            Asesor<br>Comercial #1
          </a>
        </div>
        <div class="col-50 medium-25 thankyou-page-asesor">
          <a href="https://api.whatsapp.com/send?phone=541122640003" class="external link-nolink">
            <i class="fab fa-whatsapp thankyou-page-icons"></i>
            <br>
            Asesor<br>Comercial #2
          </a>
        </div>
        <div class="col-50 medium-25 thankyou-page-asesor">
          <a href="https://api.whatsapp.com/send?phone=541163774174" class="external link-nolink">
            <i class="fab fa-whatsapp thankyou-page-icons"></i>
            <br>
            Asesor<br>Comercial #3
          </a>
        </div>
        <div class="col-50 medium-25 thankyou-page-asesor">
          <a href="https://api.whatsapp.com/send?phone=541141614921" class="external link-nolink">
            <i class="fab fa-whatsapp thankyou-page-icons"></i>
            <br>
            Asesor<br>Comercial #4
          </a>
        </div>
      </f7-row>
    </f7-block>

    <f7-row class="nav-buttons">
      <div class="col-30"></div>
      <div class="col-100 medium-40">
        <f7-button raised fill color="red" @click="reinitialize">
          <i class="fas fa-undo"></i>
          &nbsp;&nbsp;
          Realizar Otra Cotización
        </f7-button>
      </div>
      <div class="col-30"></div>
    </f7-row>
  </div>
</template>

<script>
  import utils from '../utils.js';
  import home from '../home.js';

  export default {
    props: {
      props: Object
    },
    mounted: function() {
      const self = this;
      // setTimeout(function() {
      //   self.$f7.dialog.create({
      //     title: "<img class='logo-dialog' src='static/images/logo-expreso-oro-negro.png' height='30px'>",
      //     text: "¿Le gustaría que nuestro servicio de atención al cliente se comunique con usted para confirmar la solicitud de retiro?",
      //     buttons: [
      //       {
      //         text: "No",
      //         cssClass: "btn-dialog-cancel",
      //         onClick: function(dialog, e) {
      //           self.$f7.dialog.close();
      //         }
      //       },
      //       {
      //         text: "Si",
      //         cssClass: "btn-dialog-cancel",
      //         onClick: function(dialog, e) {
      //           home.contactToPickup(self);
      //         }
      //       }
      //     ]
      //   }).open();
      // }, 2000);
    },
    methods: {
      reinitialize() {
        const self = this;
        home.reinitialize(self);
      }

    }
  }
</script>
