// Import Vue
import Vue from 'vue';

// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.css';

// Import App Component
import App from '../components/app.vue';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Init App
new Vue({
  el: '#app',
  render: (h) => h(App),
  data() {
    return {
      app_staging: false, // true => Testear con admin de Produccion | false => Testear con admin de Desarrollo
      frappe_url_dev: "https://erp.expresooronegro.reppli.com",
      frappe_url_prod: "https://admin.cotizador.expresooronegro.com",
      app_is_prod: window.location.host === "cotizadorexpress.expresooronegro.com",
      app_env: this.app_is_prod ?  "Production" : "Development",
      frappe_env: {
        "frappe_domain": "",
      }
    };
  },

  // Register App Component
  components: {
    app: App
  },
});
