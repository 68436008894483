var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('f7-sheet',{staticClass:"products-sheet",attrs:{"opened":_vm.props.productsSheetOpened},on:{"sheet:closed":function($event){_vm.props.productsSheetOpened = false},"sheet:close":_vm.calculateTotals}},[_c('f7-toolbar',[_c('div',{staticClass:"left"},[_c('f7-link',[_vm._v("Seleccione los productos")])],1),_vm._v(" "),_c('div',{staticClass:"right"},[_c('f7-link',{attrs:{"sheet-close":""}},[_vm._v("Cerrar")])],1)]),_vm._v(" "),_c('f7-page-content',[_c('f7-block',{staticStyle:{"margin":"0","padding":"10px 5px","background-color":"#ffffff","position":"sticky","top":"0","z-index":"2"}},[_c('f7-row',[_c('f7-col',[_c('f7-searchbar',{attrs:{"search-container":".search-list","disable-button":!_vm.$theme.aurora,"placeholder":"Escriba para buscar","clear-button":true}})],1)],1)],1),_vm._v(" "),_c('f7-block',{staticClass:"searchbar-not-found"},[_c('f7-row',[_c('f7-col',{staticStyle:{"width":"100%","text-align":"center","font-size":"20px"}},[_vm._v("\n          No se han encontrado resultados\n        ")])],1)],1),_vm._v(" "),_c('f7-block',{staticClass:"no-margin"},[_c('p',[_vm._v("Si el producto que desea agregar no se encuentra en la lista, haga click en el siguiente botón.")]),_vm._v(" "),_c('f7-button',{attrs:{"raised":"","fill":""},on:{"click":_vm.selectOtherProduct}},[_c('i',{staticClass:"fas fa-question"}),_vm._v(" "),_c('span',[_vm._v("  Otro Producto")])])],1),_vm._v(" "),_c('f7-block',[_c('f7-list',{staticClass:"virtual-list-products search-list searchbar-found",attrs:{"no-hairlines":"","media-list":"","virtual-list":"","virtual-list-params":{
           items: _vm.items,
           searchAll: _vm.searchAll,
           renderExternal: _vm.renderExternal,
           setListHeight: true,
           height: '40'
           }}},[_c('ul',_vm._l((_vm.vlData.items),function(product,index){return _c('f7-list-item',{key:index,staticClass:"product-item-sheet",style:('background-color:' + (_vm.props.productsIndexesSelectedSheet.indexOf(product.name) > -1 ? '#e1e1e1;' : 'inherit;') +
              'color:' + (_vm.props.productsIndexesSelectedSheet.indexOf(product.name) > -1 ? '#7d7d7d;' : 'inherit;')),attrs:{"media-item":"","title":product.name},on:{"click":function($event){return _vm.addProduct(product.index_order)}}},[_c('f7-icon',{attrs:{"slot":"media","icon":"demo-list-icon"},slot:"media"},[_c('img',{attrs:{"src":_vm.$root.frappe_env.frappe_domain + product.imagen,"height":"24","width":"24"}})])],1)}),1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }