<!-- Step 2 -->
<template id="step-2">
  <div v-if="props.stepTwoBudgetForm" class="main-column">
    <div>
      <h2 class="title-main-column">Destino y Origen</h2>
      <div class="line-below-title">////////////////////////////////////////////////////////////////////////////////////</div>
      <p>
        Para comenzar, indicanos las ubicaciones desde y hasta donde necesitas enviar la carga.
        También podes indicar si necesitas que realicemos el retiro de la mercadería, en lugar de traerla a nuestro depósito.
      </p>
    </div>

    <form id="stepTwoBudgetForm">
      <f7-row>
        <div class="col-100 xsmall-100 small-100 medium-50">
          <f7-list no-hairlines class="no-margin">
            <!-- Origen -->
            <f7-list-input
              class="item-input-with-value"
              floating-label
              outline
              required
              validate
              label="Desde"
              type="select"
              name="origen"
              :value="props.origen"
              :disabled="props.products.length > 0 ? true : false"
              @input="(e) => {props.origen = e.currentTarget.value}"
              @change="(e) => ubicacionOrigenSelected(e)"
              :onValidate="(isValid) => setFormValid(isValid)"
            >

              <option disabled selected value="">Seleccione una Ciudad</option>
              <option
                v-for="ubicacion, index in props.ubicacionesOrigen"
                :value="ubicacion"
                :key="index"
              >
                {{ ubicacion }}
              </option>
            </f7-list-input>
          </f7-list>
        </div>

        <div class="col-100 xsmall-100 small-100 medium-50">
          <f7-list no-hairlines class="no-margin">
            <!-- Destino -->
            <f7-list-input
              class="item-input-with-value"
              floating-label
              outline
              required
              validate
              label="Hasta"
              type="select"
              name="destino"
              :value="props.destino"
              :disabled="props.products.length > 0 ? true : false"
              @input="(e) => {props.destino = e.currentTarget.value}"
              @change="(e) => ubicacionDestinoSelected(e)"
              :onValidate="(isValid) => setFormValid(isValid)"
            >

              <option disabled selected value="">Seleccione una Ciudad</option>
              <option
                v-for="ubicacion, index in props.ubicacionesDestino"
                :value="ubicacion"
                :key="index"
              >
                {{ ubicacion }}
              </option>
              <span slot="info">Nuestro servicio incluye la entrega a domicilio</span>
            </f7-list-input>
          </f7-list>
        </div>
      </f7-row>

      <!-- <hr class="retiro-domicilio" v-show="props.origen === 'CABA' || props.origen === 'Buenos Aires'"> -->
      <hr class="retiro-domicilio">

      <!-- <f7-row v-show="props.origen === 'CABA' || props.origen === 'Buenos Aires'"> -->
      <f7-row>
        <div class="col-100 xsmall-100 small-100 medium-50">
          <f7-list no-hairlines class="no-margin">
            <!-- Origen Retiro -->
            <f7-list-item
              style="margin-top: 10px; margin-bottom: 10px;"
              checkbox title="Retiro de la carga a domicilio"
              name="retiro"
              :disabled="props.products.length > 0 ? true : false"
              :checked="props.origen_retiro !== '' ? true : false"
              @change="onChangeRetiroSelected"
            ></f7-list-item>
          </f7-list>
        </div>

        <div class="col-100 xsmall-100 small-100 medium-50">
          <f7-list no-hairlines class="no-margin">
            <!-- Destino Retiro -->
            <f7-list-input
              class="item-input-with-value"
              floating-label
              outline
              required
              :validate="props.retiro == 1"
              label="Localidad de Retiro"
              type="select"
              name="origen_retiro"
              :value="props.origen_retiro"
              :disabled="props.products.length > 0 || props.retiro == 0 ? true : false"
              @change="(e) => {props.origen_retiro = e.currentTarget.value}"
            >

            <option disabled selected value="">Seleccione una Localidad</option>
            <option
              v-for="(ubicacion, index) in props.ubicaciones_retiro"
              :value="ubicacion"
              :key="index"
            >
              {{ ubicacion }}
            </option>
            </f7-list-input>
          </f7-list>
        </div>

        <span v-show="props.retiro == 0 && (props.origen === 'CABA' || props.origen === 'Buenos Aires')" style="padding: 10px 16px; color: var(--f7-color-red)">
          En caso de no solicitar retiro a domicilio, podrás acercarte para enviar tu pedido.
        </span>
      </f7-row>

    </form>

    <f7-row class="nav-buttons">
      <div class="col-20 medium-10">
        <f7-button raised fill color="red" @click="reinitialize">
          <i class="fas fa-undo"></i>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button raised fill @click="backToStepOne">
          <i class="fas fa-chevron-left"></i>
          <span v-show="$f7.device.desktop">&nbsp;&nbsp;Anterior</span>
        </f7-button>
      </div>

      <div class="col-40 medium-45">
        <f7-button :disabled="!props.stepTwoBudgetFormValid" raised fill @click="submitStepTwoBudget">
          <span v-show="$f7.device.desktop">Siguiente&nbsp;&nbsp;</span>
          <i class="fas fa-chevron-right"></i>
        </f7-button>
      </div>
    </f7-row>
  </div>
</template>

  <script>
    import utils from '../utils.js';
    import home from '../home.js';

    export default {
      props: {
        props: Object
      },
      methods: {
        onChangeRetiroSelected() {
          const self = this;
          home.onChangeRetiroSelected(self);
        },
        backToStepOne() {
          const self = this;
          home.backToStepOne(self);
        },
        submitStepTwoBudget() {
          const self = this;
          home.submitStepTwoBudget(self);
        },
        reinitialize() {
          const self = this;
          home.reinitialize(self);
        },
        ubicacionOrigenSelected(e) {
          const self = this;

          self.props.destino = '';
          // self.props.origen = e.srcElement.value;
          // self.props.ubicacionesDestino = self.props.ubicaciones.slice();

          var index = self.props.ubicacionesDestino.indexOf(e.srcElement.value);
          self.props.ubicacionesDestino.splice(index, 1);

          if (e.srcElement.value === "Buenos Aires" || e.srcElement.value === "CABA") {
            var index = self.props.ubicacionesDestino.indexOf("Buenos Aires");
            self.props.ubicacionesDestino.splice(index, 1);

            var index = self.props.ubicacionesDestino.indexOf("CABA");
            self.props.ubicacionesDestino.splice(index, 1);
          } else {
            self.props.ubicacionesDestino = ["Buenos Aires"];
          }

          self.setFormValid(e.srcElement.value === '' ? false : true);
        },
        ubicacionDestinoSelected(e) {
          const self = this;

          if (self.props.origen === '') {
            self.props.origen = '';
            // self.props.origen = e.srcElement.value;
            // self.props.ubicacionesOrigen = self.props.ubicaciones.slice();

            var index = self.props.ubicacionesOrigen.indexOf(e.srcElement.value);
            self.props.ubicacionesOrigen.splice(index, 1);

            if (e.srcElement.value === "Buenos Aires" || e.srcElement.value === "CABA") {
              var index = self.props.ubicacionesOrigen.indexOf("Buenos Aires");
              self.props.ubicacionesOrigen.splice(index, 1);

              var index = self.props.ubicacionesOrigen.indexOf("CABA");
              self.props.ubicacionesOrigen.splice(index, 1);
            } else {
              self.props.ubicacionesOrigen = ["Buenos Aires"];
            }
          }

          self.setFormValid(e.srcElement.value === '' ? false : true);
        },
        setFormValid(isValid) {
          const self = this;
          self.props.stepTwoBudgetFormValid = false;

          if (self.props.origen !== "" &&
              self.props.destino !== "") {
            self.props.stepTwoBudgetFormValid = isValid;
          }
        }
      }
    }

  </script>
